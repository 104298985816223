import React, { useContext, useEffect, useRef, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import QRCode from '../../components/QRCode/QRCode';
import { StoreContext } from '../../context/StoreContext';
import { translate } from '../../translate';
import DistanceIcon from '../svg/DistanceIcon';
import LocationIcon from '../svg/LocationIcon';
import './Sidebar.scss';

type PropsType = RouteComponentProps & {
  content: any;
};

const getDistanceString = (distanceArr:any, locale:string) => {
  if (distanceArr && distanceArr.length > 0) {

    return distanceArr.reduce((acc:string, curr:string) => {
      const formatted = `${parseFloat(curr).toLocaleString(locale)} km`;
      return acc + formatted + ', ';
    }, '');
    
  }

  return '';
};

// Return the src of the image tag.
// const getImageSource = (image: string) => {
//   return process.env.REACT_APP_DATA_URL + '/' + image.split('src="/')[1].split('"')[0];
// };

const Sidebar: React.FC<PropsType> = ({ history, ...props }) => {
  const { content } = props;
  const { state } = useContext(StoreContext);
  const sidebarRef = useRef(null);
  const { 
    title, 
    field_tekst, 
    field_image, 
    field_tips, 
    field_website,
    field_website_link_title,
    field_link_qr_code,
    field_afstand,
    field_adres_address_line1,
    field_adres_locality,
    field_adres_postal_code
  } = content[0];
  const [activeClass, setActiveClass] = useState('');
  const distanceString = getDistanceString(field_afstand, state.localeAbbr);

  useEffect(() => { 
    setActiveClass('show');
  }, []);

  useEffect(() => {
    // Scroll the sidebar back to top when new sidebar is opened.
    const sidebarEl:any = sidebarRef.current;
    if (sidebarEl) {
      sidebarEl.scrollTo(0, 0);
    }

    const handleClassUpdate = () => {
      setActiveClass('');
      setTimeout(() => {
        setActiveClass('show');
      }, 250);
    };

    if (state.activePoi !== null || state.activeRoute !== null) {
      handleClassUpdate();
    }
    
  }, [state.activePoi, state.activeRoute]);

  return (
    <div className={`sidebar ${activeClass}`} ref={sidebarRef}>
      <div className="sidebar--top">
        <img className="sidebar--image" src={`${process.env.REACT_APP_DATA_URL}${field_image}`} alt={title}/>
        <div className="sidebar--content">
          <h2 className="sidebar--title">{title}</h2>
          <div className="sidebar--text" dangerouslySetInnerHTML={{ __html: field_tekst }}/>
          {field_tips.length > 0 && (
            <div className="tips">
              <h3 className="background--title">Tips</h3>
              <ul className="list">
                {field_tips.map((tip:string, i:number) => <li key={i} className="list-item sidebar--text">{tip}</li>)}
              </ul>
            </div>
          )}
          { field_afstand && distanceString && (
            <div className="text-with-icon text-with-icon__distance">
              <DistanceIcon fill="#1d9ad4" />
              <div className="text-with-icon--details">
                {translate('Distance', state.localeAbbr)}: {distanceString.slice(0, -2)}
              </div>
            </div>
          )}
          { field_adres_address_line1 && (
            <div className="text-with-icon">
              <LocationIcon fill="#1d9ad4" />
              <div className="text-with-icon--details">{field_adres_address_line1}, {field_adres_postal_code} {field_adres_locality}</div>
            </div>
          ) }
        </div>
      </div>
      <div className="sidebar--divider"/>
      { field_website && (
        <div className="sidebar--bottom">
          <div className="website--url">{field_website_link_title ? field_website_link_title : field_website}</div>
          <QRCode website={field_link_qr_code ? field_link_qr_code : field_website} />
        </div>
      ) }
    </div>
  );
};

export default withRouter(Sidebar);