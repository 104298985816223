import React, { useCallback, useContext } from 'react';
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { StoreContext } from '../../context/StoreContext';
import { translate } from '../../translate';
import RegionSelect from '../RegionSelect/RegionSelect';
import HomeIcon from '../svg/HomeIcon';
import './Navigation.scss';

// Determine what breadcrumb should be shown, if any.
const getBreadcrumb = (path:any, locale:any) => {
  switch (true) {
    case path.includes('routes'):
      return translate('Walking and cycling routes', locale);
    case path.includes('recommended'):
      return translate('Tips', locale);
    case path.includes('bucketlist'):
      return translate('Bucketlist', locale);
    case path.includes('events'):
      return translate('Events', locale);
    default:
      return false;
  }
};

const Navigation: React.FC<RouteComponentProps> = ({ history, ...props }:any) => {
  const { actions, state } = useContext(StoreContext);
  const { localeAbbr: locale } = state;
  const path = props.location.pathname;

  // Check if the breadcrumb in the footer should be visible.
  const showBreadcrumb = useCallback(() => (
    path.includes('routes') || path.includes('recommended') || path.includes('recommended')
  ), [path]);
  
  const breadcrumb = getBreadcrumb(path, locale);

  return (
    <div className="nav">
      <div className={`nav--link ${!props.match.params.region ? 'has-border-right' : ''}`}>
        <Link onClick={() => actions.setPrevious(true)} to="/"><HomeIcon color="#1d9ad4" /></Link>
      </div>
      { props.match.params.region && (
        <>
          <div className={`nav--link ${ !showBreadcrumb || !breadcrumb ? 'has-border-right' : '' } `}>
            <RegionSelect {...props} />
          </div>
          { showBreadcrumb && breadcrumb && (
            <div className="nav--link">
              <span>{breadcrumb}</span>
            </div>
          ) }
        </>
      ) }
    </div>
  );
};

export default withRouter(Navigation);