import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import Footer from '../../components/Footer/Footer';

const Bucketlist: React.FC<RouteComponentProps> = ({ history, ...props }:any) => (
  <div className="page iframe-wrapper" style={{ width: '100%', paddingBottom: '9rem' }}>
    <iframe width="100%" height="100%" title="bucketlist" src="https://am.bucketlistflemishbrabant.be/"/>
    <Footer {...props} />
  </div>
);

export default withRouter(Bucketlist);
