import React, { useCallback, useContext, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import LocaleButton from '../../components/LocaleButton/LocaleButton';
import { StoreContext } from '../../context/StoreContext';
import useFetch from '../../hooks/useFetch';
import { translate } from '../../translate';
import './Home.scss';

const Home = (props : RouteComponentProps) => {
  const { actions } = useContext(StoreContext);
  const [titleIndex, setTitleIndex] = useState(0);
  const [imageIndex, setImageIndex] = useState(0);
  const [hasPrevious, setHasPrevious] = useState(false);
  const images = useFetch('front-images', false);
  const languages = ['nl', 'fr', 'en', 'de'];

  // Reset active markers.
  useEffect(() => {
    actions.setActiveMarker(null, 'aanrader');
    actions.setActiveMarker(null, 'wandel_en_fietsroute');

    if (props.location.search.includes('clear=true')) {
      localStorage.clear();
    }
  }, []);

  // Titles slideshow.
  const cycleImages = useCallback((imgs: any) => {
    let index = 0;

    return setInterval(() => {
      index++;
      setImageIndex(index);
      if (index >= imgs.length - 1) {
        index = -1;
      }
    }, 60 * 1 * 1000);
  }, []);

  // Titles slideshow.
  const cycleTitles = useCallback(() => {
    let index = 0;

    return setInterval(() => {
      // Check to see if the cycle has ran once.
      if (!hasPrevious) {
        setHasPrevious(true);
      }

      index++;
      setTitleIndex(index);
      if (index >= languages.length - 1) {
        index = -1;
      }
    }, 10000);
  }, []);

  useEffect(() => {
    const titlesCycle = cycleTitles();
    const imagesCycle = cycleImages(images);

    return () => {
      clearInterval(titlesCycle);
      clearInterval(imagesCycle);
    };
  }, [images]);

  return (
    <div className="page home">
      {images.map((image : any, i : number) => (<div
        key={i}
        className={`bg-image ${i === imageIndex
        ? 'active'
        : ''}`}
        style={{
        backgroundImage: `url(${process.env.REACT_APP_DATA_URL}${image.image})`
      }}/>))}
      <div className="front">
        <div className="front--text">
          {languages.map((language : string, i : number) => {
            // Get the previous index te animate out the previous title.
            const cycleIndex = (titleIndex - 1 < 0)
              ? languages.length - 1
              : titleIndex - 1;
            const previous = hasPrevious
              ? cycleIndex === i
              : false;
            return (
              <div key={i}>
                <div className="title-wrapper">
                  <h1
                    className={`title ${titleIndex === i
                    ? 'active'
                    : previous
                      ? 'animate-out'
                      : ''}`}>{translate('Discover the region', language)}</h1>
                </div>
                <div className="subtitle-wrapper">
                  <h2
                    className={`subtitle ${titleIndex === i
                    ? 'active'
                    : previous
                      ? 'animate-out'
                      : ''}`}>{translate('Sights, hiking & biking routes and events', language)}</h2>
                </div>
              </div>
            );
          })}
        </div>
        <div className="front--buttons">
          <LocaleButton {...props} locale="dutch" link='/regions'/>
          <LocaleButton {...props} locale="french" link='/regions'/>
          <LocaleButton {...props} locale="english" link='/regions'/>
          <LocaleButton {...props} locale="german" link='/regions'/>
        </div>
      </div>
    </div>
  );
};

export default Home;
