import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { StoreContext } from '../../context/StoreContext';
import './LocaleButton.scss';

interface IValues {
  color: string;
  language: string;
  abbr: string;
}

interface ILocaleValues {
  [key: string]: IValues;
}

type PropsType = RouteComponentProps & {
  locale: string;
  small?: boolean;
  link?: string;
  disabled?: boolean;
};

// Get color, full name and abbreviation from the locale.
const determineValuesByLocale = (loc:string) => {
  const values:ILocaleValues = {
    dutch: { color: '#f20072', language: 'Nederlands', abbr: 'nl' },
    french: { color: '#1d9ad4', language: 'Français', abbr: 'fr' },
    german: { color: '#cf8a05', language: 'Deutsch', abbr: 'de' },
    english: { color: '#0db986', language: 'English', abbr: 'en' },
  };
  
  return values[loc];
};

const determineButtonStyle = (small:boolean|undefined, active:boolean, color:string) => {
  const style = { backgroundColor: '' };

  if (!small) {
    style.backgroundColor = color;
  } else if (active) {
    style.backgroundColor = color;
  }

  return style;
};

const determineButtonTextStyle = (small:boolean|undefined, active:boolean, color:string) => {
  const style = { color: '#fff' };

  if (small && !active) {
    style.color = color;
  } 

  return style;
};

const LocaleButton: React.FC<PropsType> = ({ history, ...props }) => {
  const { state, actions } = useContext(StoreContext);
  const { locale, small, link, disabled } = props;
  const active = state.locale === locale;
  const { color, language, abbr } = determineValuesByLocale(locale);
  const buttonStyle = determineButtonStyle(small, active, color);
  const textStyle = determineButtonTextStyle(small, active, color);

  const handleClick = () => {
    actions.setPrevious(false);
    if (disabled) {
      return;
    }
    actions.changeLocale(locale);
    // If a link has been set, we need to redirect to that link when locale changes.
    if (link) {
      history.push(link);
    }
  };

  return (
    <button
      onClick={handleClick}
      type="button"
      style={ buttonStyle }
      className={`locale-button locale-button__${locale} ${small ? 'locale-button__small' : ''} ${disabled ? 'locale-button__disabled' : ''}`}
    >
      <span className={`button--text ${!small ? 'button--text__chevron' : 'button--text__small'}`} style={ textStyle }>{small ? abbr.toUpperCase() : language}</span>
    </button>
  );
};

LocaleButton.propTypes = {
  link: PropTypes.string,
  locale: PropTypes.string.isRequired,
  small: PropTypes.bool,
};

export default withRouter(LocaleButton) as any;
