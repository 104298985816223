import QRCode from 'qrcode.react';
import React from 'react';
import { BIG_SCREEN, SMALL_SCREEN } from '../../global';
import DrawnArrowIcon from '../svg/DrawnArrowIcon';
import './QRCode.scss';

interface IProps {
  website: string;
  size?: number;
}

const SIZE = BIG_SCREEN ? 142 : SMALL_SCREEN ? 74 : 96;

const QRCodeWrapper: React.FC<IProps> = (props) => (
  <div className="website--qr">
    <DrawnArrowIcon fill="#959595"/>
    <QRCode size={props.size ? props.size : SIZE} value={props.website} />
  </div>
);

export default QRCodeWrapper;