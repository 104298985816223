import React, { useContext, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { StoreContext } from '../../context/StoreContext';
import './IconButton.scss';

type PropsType = RouteComponentProps & {
  icon: any;
  text: string|boolean;
  link: string;
  iconIsLongerThanWider?: boolean;
  hasSidebar: boolean; // check to see if the sidebar is present. If it is, we position the button more to the center.
};

const IconButton: React.FC<PropsType> = ({ history, ...props }:any) => {
  const { hasSidebar, icon, text, link, iconIsLongerThanWider } = props;
  const [disabled, setDisabled] = useState(false);
  const { actions } = useContext(StoreContext);

  const handleClick = () => {
    actions.setPrevious(false);
    setDisabled(true);
    const region = props.match.params.region;
    history.push(`/${link}/${region}`);
  };

  return (
    <button onClick={handleClick} disabled={disabled} className={`cta--button ${hasSidebar ? 'move-to-right' : ''} ${disabled ? 'disabled' : ''}`}>
      <div className={`cta--icon ${iconIsLongerThanWider ? 'cta--icon__big' : ''}`}>
        {icon}
      </div>
      <div className="cta--text">
        <span>{text}</span>
      </div>
    </button>
  );
};

export default withRouter(IconButton);
