import React from 'react';
import './ImagePlaceholder.scss';

const AfrikaIcon = () => (
  <svg className="placeholder" width="44px" height="45px" viewBox="0 0 44 45" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Group" fill="#000000">
        <path d="M37.178,29.38 L7.133,29.38 L22.239,0.746 L37.178,29.38 Z M14.38,25.01 L29.969,25.01 L22.217,10.153 L14.38,25.01 Z" id="Combined-Shape"/>
        <polygon id="Fill-1-path" points="39.444 34.249 43.935 42.644 40.082 44.706 36.826 38.619 7.407 38.619 4.201 44.705 0.334 42.668 4.769 34.249"/>
        </g>
      </g>
    </svg>
);

const ImagePlaceholder: React.FC = () => (
  <div className="image-placeholder">
    <AfrikaIcon />
  </div>
);

export default ImagePlaceholder;