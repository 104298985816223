import React, { useContext, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { StoreContext } from '../../context/StoreContext';
import LocaleButton from '../LocaleButton/LocaleButton';
import Navigation from '../Navigation/Navigation';
import ArrowIcon from '../svg/ArrowIcon';
import './Footer.scss';

// Get the previous screen based on the current screen.
const determinePreviousScreen = (path:string, regionId:string) => {
  switch (true) {
    case path.includes('recommended') || path.includes('routes') || path.includes('events') || path.includes('bucketlist'):
      return `/categories/${regionId}`;
    case path.includes('categories'):
      return `/regions`;
    case path.includes('regions'):
      return '/';
  }
};

const Footer: React.FC<RouteComponentProps> = ({ history, ...props }:any) => {
  const { actions, state } = useContext(StoreContext);
  const [clicked, setClicked] = useState(false);
  const path = history.location.pathname;
  const regionId = props.match.params.region;
  // Back button.
  const handleClick = () => {
    if (!clicked) {
      setClicked(true);

      actions.setPrevious(true);
      // Don't use history.goBack() since it could spawn unexpected results.
      const url = determinePreviousScreen(path, regionId);
      history.push(url);
    }
  };

  // The locale buttons other than dutch in the footer should not be active on events for tervuren en groene gordel & leuven.
  const disabled = path.includes('events') && regionId !== '2';
  const disabledBrussels = path.includes('events') && regionId === '2';

  return (
    <footer className="footer">
      <button className="back-button" onClick={handleClick}>
        <div className="back-button--icon">
          <ArrowIcon fill="#ffffff" />
        </div>
      </button>
      <div className="footer--bar">
        <Navigation {...props} />
        <div className="footer--right">
          { props.location.pathname.includes('events') && props.match.params.region !== '2' && (
            <img className="uitinvlaanderen--image" src={require('../../assets/img/uit-in-vlaanderen.gif')} alt="Uit in Vlaanderen"/>
          ) }
          <div className="language-switcher">
            <LocaleButton {...props} small={true} locale="dutch" />
            <LocaleButton {...props} disabled={disabled} small={true} locale="french" />
            <LocaleButton {...props} disabled={disabled} small={true} locale="english" />
            <LocaleButton {...props} disabled={disabled || disabledBrussels} small={true} locale="german" />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default withRouter(Footer);
