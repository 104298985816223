import React from 'react';

interface IProps {
  fill?: string;
}

const UserIcon: React.FC<IProps> = (props) => (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="300px" height="300px" viewBox="0 0 300 300" enableBackground="new 0 0 300 300;">
    <path fill={props.fill} d="M150,0C67.2,0,0,67.2,0,150c0,82.8,67.2,150,150,150s150-67.2,150-150C300,67.2,232.8,0,150,0z M150.5,220.8L150.5,220.8
      l-0.9,0H85.5c0-46.9,41.2-46.8,50.3-59.1l1-5.6c-12.8-6.5-21.9-22.2-21.9-40.5c0-24.2,15.7-43.7,35.1-43.7s35.1,19.6,35.1,43.7
      c0,18.2-8.9,33.8-21.6,40.4l1.2,6.3c10,11.7,49.8,12.4,49.8,58.5H150.5z"/>
  </svg>
);

export default UserIcon;