import React, { useContext } from 'react';
import { RouteComponentProps } from 'react-router';
import Card from '../../components/Card/Card';
import Footer from '../../components/Footer/Footer';
import Loader from '../../components/Loader/Loader';
import { StoreContext } from '../../context/StoreContext';
import useFetch from '../../hooks/useFetch';

interface IPaths {
  [key: string]: string;
}

interface ICategory {
  title: string;
  field_subtitel: string|boolean;
  field_image: string;
  field_category_type: string;
}

const getPathPrefixByCategoryType = (type:string) => {
  const paths:IPaths = {
    events: 'events',
    routes: 'routes',
    pois: 'recommended',
    bucketlist: 'bucketlist'
  };

  return paths[type] || '';
};

const isDisabled = (category:any, locale:string, region:string) => {
  if (region === '2') {
    return category.field_category_type === 'events' && locale === 'de';
  }

  return category.field_category_type === 'events' && locale !== 'nl';
};

const Categories: React.FC<RouteComponentProps> = React.memo((props:any) => {
  const { region } = props.match.params;
  const categories = useFetch('categorie', true, { key: 'field_regio', value: region });
  const { state } = useContext(StoreContext);
  const { localeAbbr: locale } = state;

  return (
    <div className="page categories">
      {categories ? (
        <div className="card-wrapper">
          {categories.map((category:ICategory, i) => {
            const pathPrefix = getPathPrefixByCategoryType(category.field_category_type);

            const disabled = isDisabled(category, locale, region);

            return (
              <Card
                link={`/${pathPrefix}/${region}`}
                small={true}
                key={i}
                title={category.title}
                subtitle={category.field_subtitel}
                image={category.field_image}
                // Events from uit in vlaanderen aren't translated. The corresponding card should therefor be disabled.
                disabled={disabled}
              />
            );
          })}
        </div>
      ) : (
        <Loader />
      )}
      <Footer {...props} />
    </div>
  );
});

export default Categories;