import React, { useContext, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import Select from 'react-select';
import { SelectComponentsProps } from 'react-select/lib/Select';
import { StoreContext } from '../../context/StoreContext';
import useCurrentRegion from '../../hooks/useCurrentRegion';
import useTranslatedRegions from '../../hooks/useTranslatedRegions';
import './RegionSelect.scss';

type PropsType = RouteComponentProps & {
  selectProps?: SelectComponentsProps;
};

// A custom component for the dropdown inidicator. This makes styling it easier.
const DropdownIndicator = (props:SelectComponentsProps) => {
  return (
    <div className={`dropdown-indicator ${props.selectProps.menuIsOpen ? 'active' : ''}`}/>
  );
};

// Format the current region to match the select component value.
const formatRegionValue = (currentRegion:any) => (
  { label: currentRegion.name, value: currentRegion.tid }
);

const getSelectOptions = (regions:[], value:any, path:string) => {
  // Format to { label: [label], value: [value] }.
  let options = regions.map((reg:any) => ({ label: reg.name, value: reg.tid }));
  // Remove the selected option from the options array.
  options = options.filter((option:any) => option.label !== value.label && option.value !== value.value);
  // Remove brussels when on bucketlist page.
  if (path.includes('bucketlist')) {
    options = options.filter((option:any) => option.value !== '2');
  }

  return options;
};

const RegionSelect: React.FC<PropsType> = ({ history, ...props }:any) => {
  const [value, setValue] = useState<any>({ label: "", value: "" });
  const [options, setOptions] = useState<any>([]);
  const regionId = props.match.params.region;
  const path = props.location.pathname;
  const { state } = useContext(StoreContext);
  const regions = useTranslatedRegions(state.locale);
  const currentRegion = useCurrentRegion(regionId);

  useEffect(() => {
    // Get the current region.
    const region = regions && currentRegion ? formatRegionValue(currentRegion) : false;
    if (region) {
      setValue(region);
    }
  }, [state.locale]);

  useEffect(() => {
    const filteredOptions = getSelectOptions(regions, value, path);
    setOptions(filteredOptions);
  }, [value]);
  
  const optionsGroup = [
    {
      label: 'Kies een andere regio:',
      options
    }
  ];
  
  const onChange = (val:any) => {
    // Explode the path so we can change the last element (which is always the region id).
    const exploded = path.split('/');
    // Set the last item as the new id of the region.
    exploded[exploded.length - 1] = val.value;
    // Construct a new path with the updated value.
    const newPath = exploded.join('/');
    // Go to the new url.
    history.push(newPath);
  };

  // Styling the select component.
  const customStyles = {
    container: () => ({
      minWidth: 320,
      padding: '0 1.5rem',
      width: '20.5vw',
    }),
    control: () => ({
      border: 0,
      display: 'flex'
    }),
    dropdownIndicator: (provided:any, selectState:any) => ({
      color: '#1d9ad4',
      transform: selectState.selectProps.menuIsOpen ? "rotateX(180deg)" : "rotateX(0deg)",
      transition: 'all .5s ease-out',
    }),
    group: (provided:any) => ({
      ...provided,
      padding: 0
    }),
    groupHeading: (provided:any) => ({
      ...provided,
      borderBottom: '1px solid rgba(0,0,0,.1)',
      color: '#363636',
      fontWeight: 'bold',
      letterSpacing: 0,
      marginBottom: 0,
      padding: 25,
      textTransform: 'initial',
    }),
    menu: (provided:any) => ({
      ...provided,
      borderBottom: 0,
      borderRadius: 0,
      boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
      margin: '0 0 0 -1.5rem',
      transition: 'all .25s ease-out',
      zIndex: -1
    }),
    menuList: (provided:any) => ({
      ...provided,
      padding: 0,
    }),
    option: (provided:any) => {
      return ({
        ...provided,
        ':active': {
          ...provided[':active'],
          backgroundColor: '#1d9ad4',
          color: '#fff',
        },
        backgroundColor: 'transparent',
        borderBottom: '1px solid rgba(0,0,0,.1)',
        padding: 20,
      });
    },
  };

  return (
    <Select
      styles={customStyles}
      classNamePrefix="react-select"
      options={optionsGroup}
      isSearchable={false}
      menuPlacement="top"
      onChange={onChange}
      value={value}
      components={{
        DropdownIndicator,
        IndicatorSeparator: () => null,
      }}
    />
  );
};

export default withRouter(RegionSelect);