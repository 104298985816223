import { GET_REGIONS, SET_ACTIVE_POI, SET_ACTIVE_ROUTE, SET_LOCALE, SET_PREVIOUS } from './types';

interface ILocaleAbbr {
  [key: string]: string;
}

export const useActions = (state: string, dispatch: any) => {
  const getLocaleAbbr = (locale:string) => {
    const localeAbbr:ILocaleAbbr = {
      dutch: 'nl',
      french: 'fr',
      german: 'de',
      english: 'en',
    };

    return localeAbbr[locale] || 'nl';
  };
  // Change the language of the app.
  const changeLocale = (locale: string) => {
    const abbr = getLocaleAbbr(locale);
    document.documentElement.lang = abbr;
    const data = { locale, abbr };
    dispatch({ type: SET_LOCALE, payload: data });
  };

  const getAllRegions = async () => {
    const regions = localStorage.getItem('regions');
    // Get the date when the regions were put in the local storage and compare it with the current date.
    // When the day that the regions've been set is not the same as the current date,
    // we fetch the regions again from the backend.
    const dayRegionsSet = localStorage.getItem('regionsSetOn');
    const currentDay = new Date().getDay();
    // Get the regions from the localstorage if they exist there.
    // We cache the regions for a day.
    if (regions && dayRegionsSet && currentDay - parseInt(dayRegionsSet, 10) === 0) {
      return;
    }
    // When cache time is over or regions aren't in local storage, we fetch all the regions.
    const url = `${process.env.REACT_APP_DATA_URL}/regio`;

    try {
      const data = await fetch(url);
      const json = await data.json();
      localStorage.setItem('regions', JSON.stringify(json));
      localStorage.setItem('regionsSetOn', new Date().getDay().toString());
    } catch (err) {
      console.error(err);
    }
  };

  // Get regions from API.
  const getRegions = async (locale: string) => {
    await getAllRegions();

    dispatch({ type: GET_REGIONS, payload: localStorage.getItem('regions') });
  };
  // Set active point of interest or route.
  const setActiveMarker = (id: string|number|null, contentType:string) => {
    const type = contentType === 'aanrader' ? SET_ACTIVE_POI : SET_ACTIVE_ROUTE;
    dispatch({ type, payload: id });
  };

  const setPrevious = (previous:boolean) => {
    dispatch({ type: SET_PREVIOUS, payload: previous });
  };

  return {
    changeLocale,
    getRegions,
    setActiveMarker,
    setPrevious,
  };
};
