import React from 'react';
import ReactGA from 'react-ga';
import { BrowserRouter as Router } from 'react-router-dom';
import Container from './components/Container/Container';
import './styles.scss';

const App = () => {
  ReactGA.initialize('UA-144356284-1');
  ReactGA.pageview(window.location.pathname + window.location.search);

  return (
    <Router>
      <Container />
    </Router>
  );
};

export default App;
