import React from 'react';

interface IProps {
  fill?: string;
}

const DistanceIcon: React.FC<IProps> = (props) => (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="512px" height="512px" viewBox="0 0 512 512" enableBackground="new 0 0 512 512;">
  <path fill={props.fill} d="M426.7,0c-47.1,0-85.3,38.3-85.3,85.3c0,40.8,60.5,123.4,74.6,142.2c0,6.1,0,12-0.1,17.5c-0.2,5.9,4.5,10.8,10.4,10.9
    c0.1,0,0.2,0,0.3,0c5.8,0,10.5-4.6,10.7-10.4c0.1-5.6,0.1-11.7,0.1-18C451.4,209,512,126.2,512,85.3C512,38.3,473.7,0,426.7,0z
    M426.7,106.7c-11.8,0-21.3-9.6-21.3-21.3S414.9,64,426.7,64S448,73.6,448,85.3C448,97.1,438.4,106.7,426.7,106.7z"/>
  <path fill={props.fill} d="M326.5,307.4c-17.6,10-27.4,27.3-33.2,39.5c-2.6,5.3-0.3,11.7,5,14.2c1.5,0.7,3.1,1.1,4.6,1.1c4,0,7.8-2.2,9.6-6.1
    c7.4-15.4,15-24.8,24.5-30.2c5.1-2.9,6.9-9.4,4-14.5C338.1,306.3,331.7,304.5,326.5,307.4z"/>
  <path fill={props.fill} d="M229.4,462.3c-10.7,8.5-22.8,14.8-37.1,19.5c-5.6,1.8-8.7,7.8-6.8,13.4c1.5,4.5,5.6,7.4,10.1,7.4c1.1,0,2.2-0.2,3.3-0.5
    c16.5-5.4,31.3-13.1,43.8-23.1c4.6-3.7,5.4-10.4,1.7-15C240.7,459.4,234,458.6,229.4,462.3z"/>
  <path fill={props.fill} d="M289.6,384.4c-5.5-2.1-11.7,0.6-13.8,6.1c-4.8,12.4-10.8,26.8-18.8,40c-3.1,5-1.5,11.6,3.5,14.7c1.7,1.1,3.7,1.6,5.6,1.6
    c3.6,0,7.1-1.8,9.1-5.1c8.9-14.6,15.4-30.1,20.5-43.4C297.8,392.8,295.1,386.6,289.6,384.4z"/>
  <path fill={props.fill} d="M422.7,281c-4.8-3.3-11.5-2-14.8,2.9c-3.5,5.2-10.7,12.3-32.4,14.3c-5.9,0.5-10.2,5.7-9.7,11.6c0.5,5.5,5.2,9.7,10.6,9.7
    c0.3,0,0.6,0,1,0c23.1-2.1,38.9-9.8,48.2-23.6C428.9,291,427.6,284.3,422.7,281z"/>
  <path fill={props.fill} d="M153.3,490.8C186.9,454.9,256,372.3,256,298.7c0-71.8-56.2-128-128-128S0,226.9,0,298.7c0,94.6,114.1,204.1,120.4,210.2
    c0,0,0,0,0,0c0,0,0.2,0.2,0.2,0.2c0.4,0.4,1,0.6,1.5,0.9c0.6,0.4,1.2,0.9,1.8,1.2c1.3,0.5,2.6,0.8,4,0.8c7.8,0,15.2-0.3,22.2-0.8
    c5.9-0.4,10.3-5.6,9.8-11.4C159.7,495.6,156.9,492.3,153.3,490.8z M128,362.7c-35.3,0-64-28.7-64-64s28.7-64,64-64s64,28.7,64,64
    C192,334,163.3,362.7,128,362.7z"/>
</svg>
);

export default DistanceIcon;