import React, { useContext } from "react";
import ReactGA from 'react-ga';
import IdleTimer from 'react-idle-timer';
import { Route, RouteComponentProps, Switch, withRouter } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { StoreContext } from "../../context/StoreContext";
import Bucketlist from "../../pages/Bucketlist/Bucketlist";
import Categories from '../../pages/Categories/Categories';
import Events from "../../pages/Events/Events";
import Home from '../../pages/Home/Home';
import Recommended from '../../pages/Recommended/Recommended';
import Regions from '../../pages/Regions/Regions';
import Routes from "../../pages/Routes/Routes";
import './Container.scss';

const Container: React.FC<RouteComponentProps> = ({ history, ...props }) => {
  const { state } = useContext(StoreContext);
  // Return to homepage when idle is called.
  const onIdle = () => {
    if (props.location.pathname === '/') {
      return;
    }

    history.push('/');
  };

  ReactGA.pageview(window.location.pathname + window.location.search);

  return (
      <TransitionGroup>
        <IdleTimer
          element={document}
          onIdle={onIdle}
          timeout={60 * 2 * 1000} />
        <CSSTransition
          key={props.location.key}
          timeout={{ enter: 500, exit: 500 }}
          classNames={state.previous ? 'page-prev' : 'page'}
        >
          <Switch location={props.location}>
            <Route exact={true} path="/" component={Home} />
            <Route path="/regions" component={Regions} />
            <Route path="/categories/:region" component={Categories} />
            <Route path="/recommended/:region" component={Recommended} />
            <Route path="/routes/:region" component={Routes} />
            <Route path="/events/:region" component={Events} />
            <Route path="/bucketlist/:region" component={Bucketlist} />
          </Switch>
        </CSSTransition>
      </TransitionGroup>
  );
};

export default withRouter(Container);