import React from 'react';

interface IProps {
  fill?: string;
}

const EuroIcon: React.FC<IProps> = (props) => (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="486px" height="486px" viewBox="0 0 486 486" enableBackground="new 0 0 486 486;">
    <path fill={props.fill} d="M243,0C108.8,0,0,108.8,0,243s108.8,243,243,243s243-108.8,243-243S377.2,0,243,0z M327.8,366.1
      c-7.3,3.3-14.9,6.1-22.7,8.2c-29.6,8.2-59.2,8.5-88.7-1.1c-35.6-11.5-59.2-35.8-73.3-69.8c-2.7-6.6-4.5-13.5-6.6-20.4h-27.9
      c-4.7,0-8.4-3.8-8.4-8.4v-18.8c0-4.7,3.8-8.4,8.4-8.4h23.1c0-5.2,0-10.1,0-15.3h-23.1c-4.7,0-8.4-3.8-8.4-8.4v-18.8
      c0-4.7,3.8-8.4,8.4-8.4H138l1-2.9c7.9-25.1,21.5-46.6,42.4-62.8c16.6-12.9,35.8-20,56.4-23.3c29.3-4.7,57.7-0.7,85.2,10.1
      c1.8,0.7,3.5,1.5,5.2,2.5c6.6,3.9,8.3,8.8,5.4,15.9c-3.1,7.4-6.2,14.9-9.6,22.2c-2.9,6.3-6.9,8.3-13.7,6.8
      c-9.3-2.1-18.4-5-27.7-6.9c-19.6-4-39.1-4.2-58.2,3.3c-16.4,6.5-26.4,19-33.2,34.6c-0.1,0.2-0.1,0.4-0.1,0.6h57.1
      c4.7,0,8.4,3.8,8.4,8.4v18.8c0,4.7-3.8,8.4-8.4,8.4H183c-0.1,5,0,10,0,15.3h65.3c4.7,0,8.4,3.8,8.4,8.4v18.8
      c0,4.7-3.8,8.4-8.4,8.4h-59.6c7.2,20.6,18.9,36.5,40.9,43.4c17.6,5.5,35.3,5.2,53.1,1.5c9.2-1.9,18.2-4.7,27.4-6.8
      c7.2-1.6,11.1,0.3,14.1,6.9c3.4,7.4,6.6,14.9,9.6,22.5C336.6,357.4,334.8,362.9,327.8,366.1z"/>
  </svg>
);

export default EuroIcon;