import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { translate } from '../../translate';
import ClockIcon from '../svg/ClockIcon';
import './Date.scss';

interface IOpeninghourProps {
  times: string[];
}

interface ISingleDateProps {
  date: string|undefined;
  startTime: string|boolean;
  endTime: string|boolean;
  locale: string;
}

type IDateProps = RouteComponentProps & {
  dates: string[]; // Multiple dates for an event.
  startDate: string;
  endDate: string;
  locale: string;
  openingHours: []|boolean;
};

// Single date + time line component.
const SingleDate: React.FC<ISingleDateProps> = (props) => (
  <>
    <div className="inline date">{props.date}</div>
    { props.startTime && props.endTime && (
      <div className="inline time">
        <ClockIcon fill="#1d9ad4" />
        <p className="align__middle">{props.startTime} {translate('until', props.locale)} {props.endTime}</p>
      </div>
    ) }
  </>
);

const Openinghours: React.FC<IOpeninghourProps> = (props) => (
  <>
    { props.times.map((time:string, i:number) => (
      <div key={i} className="opening_hours time">
        <ClockIcon fill="#1d9ad4" />
        <span className="align__middle">{time}</span>
      </div>
    )) }
  </>
);

const formattedDate = (start:string, end:string, locale:string) => {
  const formattedStartdate = formatDate(start, locale);
  const formattedEnddate = formatDate(end, locale);

  return {
    date: formattedStartdate.date,
    startTime: formattedStartdate.time,
    endTime: formattedEnddate.time,
  };
};

const formatOpeningHours = (openingHours:any, locale:string) => {
  const formatted = openingHours.map((openingHour:any) => {
    // Get the translated days of the week.
    const translatedDays = openingHour.dayOfWeek.map((day:string) => translate(day, locale));
    // If there are multiple days, we shorten them to "firstDay - lastDay"
    const days = (translatedDays.length > 1) ? `${translatedDays[0]} - ${translatedDays[translatedDays.length - 1]}` : translatedDays[0];

    return `${days} ${translate('from', locale)} ${openingHour.opens} ${translate('until', locale)} ${openingHour.closes}`;
  });

  return formatted;
};

// Format date when data comes from Uit in Vlaanderen.
const formatDatesUitInVlaanderen = (dates:any, locale:string) => {
  if (dates.length <= 0) {
    return dates;
  }

  return Array.isArray(dates) && dates.length > 0
    ? dates.map((subdate:any) => {
      return formattedDate(subdate.startDate, subdate.endDate, locale);
    })
    : [];
};
// Format date when data comes from Brussels API.
const formatDatesBrussels = (dates:any, locale:string) => {
  if (dates.length <= 0) {
    return dates;
  }

  if (Array.isArray(dates) && dates.length > 0) {
    return dates.map((subdate:any) => {
      const startDate = (subdate.start) ? `${subdate.day} ${subdate.start}` : `${subdate.day} 00:00`;
      const endDate = (subdate.end) ? `${subdate.day} ${subdate.end}` : `${subdate.day} 23:59`;

      return formattedDate(startDate, endDate, locale);
    });
  }

  return [formattedDate(`${dates.day} ${dates.start}`, `${dates.day} ${dates.end}`, locale)];
};

// Format date to weekday dd month yyyy and time to hh:mm
const formatDate = (unformattedDate:string, locale:string) => {
  const dateOptions = { weekday: 'long', month: 'long', day: 'numeric', year: 'numeric' };
  const noTime = unformattedDate.includes('none');

  if (noTime) {
    unformattedDate = unformattedDate.replace('none', '').trim();
  }

  const dateObject = new Date(unformattedDate);
  const localeDate = dateObject.toLocaleDateString(locale, dateOptions);

  if (noTime) {
    return { date: localeDate, time: false };
  }

  // Add a 0 before hours < 10
  const hour = dateObject.getHours().toString().length < 2 ? "0" + dateObject.getHours() : dateObject.getHours();
  // Add a 0 before minutes < 10
  const minute = dateObject.getMinutes().toString().length < 2 ? "0" + dateObject.getMinutes() : dateObject.getMinutes();
  const time = `${hour}:${minute}`;

  return { date: localeDate, time };
};

const DateComponent: React.FC<IDateProps> = ({ history, ...props }:any) => {
  const { locale, openingHours } = props;

  const startDate = formatDate(props.startDate, locale);
  const endDate = formatDate(props.endDate, locale);

  const openingTimes = (openingHours) ? formatOpeningHours(openingHours, locale) : false;

  // If there are multiple dates for an event, we seperate and format each of them.
  const dates = (props.match.params.region === '2') ? formatDatesBrussels(props.dates, locale) : formatDatesUitInVlaanderen(props.dates, locale);
  // If multiple different dates are given, we print those as single dates.
  if (dates.length > 0) {
    return (
      <>
        {dates.map((subdate:any, i:number) => (
          <div key={i} className="single-date">
            <SingleDate
              date={subdate.date}
              locale={locale}
              startTime={subdate.startTime}
              endTime={subdate.endTime}
            />
          </div>
        ))}
      </>
    );
  }
  // Check if the startday of the event is the same as the end day.
  // If so, we combine these two in one date.
  const date = startDate.date === endDate.date
    ? { date: startDate.date, startTime: startDate.time, endTime: endDate.time }
    : { startDate: startDate.date, startTime: startDate.time, endDate: endDate.date, endTime: endDate.time };

  return (
    <>
      { date.startDate ? (
        <>
          <div className="inline date">{date.startDate} - {date.endDate}</div>
           { openingTimes && openingTimes.length > 0 ? (
             <Openinghours times={openingTimes} />
           ) : (
            <>
              { date.startTime && date.endTime && (
                <div className="inline time">
                  <ClockIcon fill="#1d9ad4" />
                  <p className="align__middle">{date.startTime} {translate('until', locale)} {date.endTime}</p>
                </div>
              ) }
            </>
           )}
        </>
      ) : (
        <SingleDate
          date={date.date}
          locale={locale}
          startTime={date.startTime}
          endTime={date.endTime}
        />
      )}
    </>
  );
};

export default withRouter(DateComponent);
