import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import QRCode from '../../components/QRCode/QRCode';
import { BIG_SCREEN } from '../../global';
import { translate } from '../../translate';
import DateComponent from '../Date/Date';
import ImagePlaceholder from '../ImagePlaceholder/ImagePlaceholder';
import EuroIcon from '../svg/EuroIcon';
import GlobeIcon from '../svg/GlobeIcon';
import LocationIcon from '../svg/LocationIcon';
import UserIcon from '../svg/UserIcon';
import './Event.scss';

interface IProps {
  title: string;
  image: string;
  startDate: string;
  endDate: string;
  location: any;
  description: string;
  price: string|number;
  ageRange: string|false;
  locale: string;
  url: string;
  website: string|boolean;
  dates: string[];
  openingHours: []|boolean;
}

const DESCRIPTION_MAX_LENGTH = BIG_SCREEN ? 600 : 300; // 4K = 650, HD = 300
// Shorten the description based on other fields.
const getDescriptionLengthByProps = ({ dates, age }:any) => {
  let length = DESCRIPTION_MAX_LENGTH;
  if (dates.length > 0) {
    length -= dates.length * 50;
  }

  if (age) {
    length -= 150;
  }

  return length;
};

// Shorten a string by a max amount of characters.
const shorten = (str:string, len:number) => {
  if (str.length <= len) {
    return str;
  }

  let result = str.substr(0, len - 1);
  result = result.substr(0, Math.min(result.length, result.lastIndexOf(" ")));

  return result + '…';
};

// When "age to" equals 99, we format the age restriction to "age+".
const formatAgeRange = (ageRange:string, locale:string) => {
  if (ageRange === '-') {
    return false;
  }

  const splittedRange = ageRange.split('-');
  if (!splittedRange[splittedRange.length - 1] || parseInt(splittedRange[splittedRange.length - 1], 10) >= 99) {
    return `${splittedRange[0]}+`;
  }

  return `${splittedRange.join(' - ')} ${translate('year', locale)}`;
};

const Event: React.FC<IProps> = (props) => {
  const { title, image, location, description, price, locale, url, website, dates, startDate, endDate, openingHours } = props;
  const age = props.ageRange ? formatAgeRange(props.ageRange, locale) : false;
  const descriptionLength = getDescriptionLengthByProps({ dates, age });

  return (
    <div className="event">
      <div className="event--top">
        <div className="event--image-wrapper">
          { image ? (
            <LazyLoadImage
              className="event--image"
              alt={title}
              src={image}
              width='100%'
              height='25vh'
              placeholder={<ImagePlaceholder />} />
          ) : (
            <ImagePlaceholder />
          ) }
        </div>
        <div className="event--content">
          <h2>{title}</h2>
          <div className="event--dates">
            { startDate && endDate && (
              <DateComponent dates={dates} startDate={startDate} endDate={endDate} openingHours={openingHours} locale={locale} />
            ) }
          </div>
          { description && (
            <p className="event--text">
              {shorten(description, descriptionLength)}
            </p>
          ) }
          {location && (
            <div className="event--location">
              <LocationIcon fill="#1d9ad4" />
              <div className="inline location--details">
                <span className="location--title"><strong>{location.name}</strong></span>
                <div className="location--address">{location.streetAddress}</div>
                <div className="location--locality">{location.postalCode} {location.addressLocality}</div>
              </div>
            </div>
          )}
          { price !== undefined && (
            <div className="event--price">
              <EuroIcon fill="#1d9ad4" />
              <strong>{price === 0 && locale ? translate('free', locale) : `€ ${price}`}</strong>
            </div>
          )}
          { age && (
            <div className="event--age">
              <UserIcon fill="#1d9ad4" />
              <strong>{age}</strong>
            </div>
          ) }
          { website && (
            <div className="event--website">
              <GlobeIcon fill="#1d9ad4" />
              <strong>{website}</strong>
            </div>
          ) }
        </div>
        </div>
      { url && (
        <div className="event--bottom">
          <div className="website--url">
            { translate('Scan the QR code for more info.', locale) }
          </div>
          <QRCode website={url} />
        </div>
      )}
    </div>
  );
};

export default Event;