import React from 'react';

interface IProps {
  fill?: string;
}

const ClockIcon: React.FC<IProps> = (props) => (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="612px" height="611.7px" viewBox="0 0 612 611.7" enableBackground="new 0 0 612 611.7;" >
    <path fill={props.fill} d="M587.6,186.7c-32.3-75.2-87.1-129.9-162.9-162.3C386.7,8.3,347,0,305.5,0C264,0,224.6,8.2,186.7,24.4
      C111.5,56.7,56.4,111.4,24.1,186.7C7.9,224.5,0,264,0,305.5c0,41.5,7.9,81.4,24.1,119.1C56.4,500,111.5,555,186.7,587.2
      c37.9,16.2,77.3,24.4,118.8,24.4s81.2-8.3,119.1-24.4c75.9-32.4,130.7-87.4,163-162.6C603.8,386.7,612,347,612,305.5
      C612,264,603.8,224.6,587.6,186.7z M538.7,440.7c-24,41.2-56.9,73.9-98.4,98c-41.2,24-86.3,36.1-134.8,36.1
      c-36.5,0-71.3-7-104.4-21.4c-33.1-14.4-61.7-33.3-85.7-57.2c-23.9-23.9-43-52.8-57.2-86C44,377.2,37.1,342,37.1,305.5
      C37.1,257,49.1,212.1,73,171c24-41.2,56.9-73.9,98-98c41.1-24.1,86-36.1,134.5-36.1c48.5,0,93.6,12.1,134.8,36.1
      c41.4,24.2,74.3,56.9,98.4,98c24.1,41.2,36.1,86,36.1,134.5C574.9,354,562.9,399.2,538.7,440.7z"/>
    <path fill={props.fill} d="M324.9,302.8V129.5c0-10.4-9-18.7-19.4-18.7c-9.7,0-18.4,8.4-18.4,18.7v176c0,0.3,0.7,1.7,0.7,2.7c-0.7,6,1,11,5,15.1
      l100.1,100c6.7,6.7,19.1,6.7,25.8,0c7.7-7.7,7.2-18.9,0-26.1L324.9,302.8z"/>
  </svg>
);

export default ClockIcon;