export default [
  {
    en: "Discover the region",
    nl: "Ontdek de regio",
    fr: "Découvrez la région",
    de: "Entdecken Sie die Region"
  },
  {
    en: "Sights, hiking & biking routes and events",
    nl: "Bezienswaardigheden, wandel- fietsroutes en evenementen",
    fr: "Itinéraires et événements touristiques, pédestres et cyclistes",
    de: "Sehenswürdigkeiten, Wander- und Radwege und Veranstaltungen"
  },
  {
    en: 'Walking and cycling routes',
    nl: 'Wandel- en fietsroutes',
    fr: 'Itinéraires pédestres et cyclables',
    de: 'Wander- und Fahrradwege',
  },
  {
    en: 'Tips',
    nl: 'Aanraders',
    fr: 'Recommandations',
    de: 'Empfehlungen',
  },
  {
    en: 'Events',
    nl: 'Evenementen',
    fr: 'Événements',
    de: 'Veranstaltungen',
  },
  {
    en: 'Bucketlist',
    nl: 'Bucketlist',
    fr: 'Bucketlist',
    de: 'Bucketlist',
  },
  {
    en: 'Distance',
    nl: 'Afstand',
    fr: 'Distance',
    de: 'Entfernung'
  },
  {
    en: 'free',
    nl: 'gratis',
    fr: 'gratuit',
    de: 'frei'
  },
  {
    en: 'until',
    nl: 'tot',
    fr: 'jusqu\'a',
    de: 'bis'
  },
  {
    en: 'year',
    nl: 'jaar',
    fr: 'ans',
    de: 'Jahre'
  },
  {
    en: 'Only available in dutch',
    nl: 'Alleen beschikbaar in nederlands',
    fr: 'Seulement disponible en néerlandais',
    de: 'Nur verfügbar in niederlandisch'
  },
  {
    en: 'Only available in french',
    nl: 'Alleen beschikbaar in frans',
    fr: 'Seulement disponible en français',
    de: 'Nur verfügbar in französisch'
  },
  {
    en: 'Only available in english',
    nl: 'Alleen beschikbaar in engels',
    fr: 'Seulement disponible en anglais',
    de: 'Nur verfügbar in englisch'
  },
  {
    en: 'Only available in german',
    nl: 'Alleen beschikbaar in duits',
    fr: 'Seulement disponible en allemand',
    de: 'Nur verfügbar in niederlandisch'
  },
  {
    en: 'sunday',
    nl: 'zondag',
    fr: 'dimanche',
    de: 'Sonntag'
  },
  {
    en: 'monday',
    nl: 'maandag',
    fr: 'lundi',
    de: 'Montag'
  },
  {
    en: 'tuesday',
    nl: 'dinsdag',
    fr: 'mardi',
    de: 'Dienstag'
  },
  {
    en: 'wednesday',
    nl: 'woesndag',
    fr: 'mercredi',
    de: 'Mittwoch'
  },
  {
    en: 'thursday',
    nl: 'donderdag',
    fr: 'jeudi',
    de: 'Donnerstag'
  },
  {
    en: 'friday',
    nl: 'vrijdag',
    fr: 'vendredi',
    de: 'Freitag'
  },
  {
    en: 'saturday',
    nl: 'zaterdag',
    fr: 'samedi',
    de: 'Samstag'
  },
  {
    en: 'from',
    nl: 'van',
    fr: 'de',
    de: 'von'
  },
  {
    en: 'Scan the QR code for more info.',
    nl: 'Scan de QR code voor meer info.',
    fr: 'Scannez le code QR.',
    de: 'Scannen Sie den QR-Code.',
  },
  {
    en: 'An error occured',
    nl: 'Er is een fout opgetreden',
    fr: 'Une erreur s\'est produite',
    de: 'Ein Fehler ist aufgetreten',
  },
  {
    en: 'No events were found',
    nl: 'Er werden geen evenementen gevonden',
    fr: 'Aucun événement n\'a été trouvé',
    de: 'Es wurden keine Veranstaltungen gefunden',
  }
];
