import React, { useContext, useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import Footer from '../../components/Footer/Footer';
import IconButton from '../../components/IconButton/IconButton';
import Loader from '../../components/Loader/Loader';
import Map from '../../components/Map/Map';
import Sidebar from '../../components/Sidebar/Sidebar';
import LikeIcon from '../../components/svg/LikeIcon';
import { StoreContext } from '../../context/StoreContext';
import useCurrentRegion from '../../hooks/useCurrentRegion';
import useFetch from '../../hooks/useFetch';
import { translate } from '../../translate';

const Routes: React.FC<RouteComponentProps> = ({ history, ...props }:any) => {
  const { state, actions } = useContext(StoreContext);
  const regionId = props.match.params.region;
  const region = useCurrentRegion(regionId);
  const coords:any = useFetch(`regio/coords/${regionId}`, false);
  const routes = useFetch('route', true, { key: 'field_regio', value: regionId });
  const content = routes.length > 0 ? routes : [];
  const currentRoute = (state.activeRoute) ? routes.filter((route:any) => route.nid === state.activeRoute) : null;

  // Unset the active marker of routes on onmount.
  useEffect(() => {
    return actions.setActiveMarker(null, 'routes');
  }, []);

  return (
    <div className="page routes">
      {region && content.length > 0 && coords.length > 0 ? (
        <>
          <Map polygons={coords[0].field_coordinates} content={content} />
          {(currentRoute && currentRoute.length > 0) && (
            <Sidebar content={currentRoute} />
          )}
          <IconButton link='recommended' hasSidebar={state.activeRoute !== null} icon={<LikeIcon fill='#ffffff' />} text={translate("Tips", state.localeAbbr)} />
          <Footer />
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default withRouter(Routes);