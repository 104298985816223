import React, { useContext, useEffect, useState } from 'react';
import { StoreContext } from '../../context/StoreContext';
import { translate } from '../../translate';
import Footer from '../Footer/Footer';
import './Loader.scss';

interface IProps {
  message?: string|boolean;
}

const Loader: React.FC<IProps> = (props: any) => {
  const { actions, state } = useContext(StoreContext);
  const { locale } = state;
  const [showImage, setShowImage] = useState(false);
  const [showError, setShowError] = useState(false);

  // We delay showing the loader to prevent unnecessairy flickering of the loader.
  useEffect(() => {
    const showLoader = (() => {
      return setTimeout(() => {
        setShowImage(true);
      }, 1000);
    })();

    const showErrorMessage = (() => {
      return setTimeout(() => {
        setShowError(true);
      }, 8500);
    })();

    return () => {
      clearTimeout(showLoader);
      clearTimeout(showErrorMessage);
    };
  }, []);

  return (
    <>
      <div className={`error ${showError && 'show'}`}>
        <h2>Error</h2>
        <div className="divider"/>
        <p>{props.message || translate('An error occured', locale)}</p>
      </div>
      <div className={`loader ${showError && 'hide'}`}>
        <img className={`${showImage ? 'show' : ''}`} src={require('../../assets/img/loader.gif')} alt=""/>
        <Footer />
      </div>
      )}
    </>
  );
};

export default Loader;
