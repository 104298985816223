import { any } from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { StoreContext } from '../../context/StoreContext';
import './Card.scss';

interface ICardWrapper {
  className: string;
  style: {};
  to: string;
  disabled?: boolean;
}

interface IProps {
  title: string;
  subtitle?: string|boolean;
  image: string;
  topImage?: string;
  small?: boolean;
  link: string;
  disabled?: boolean;
}

// A wrapper element to check if the state of the card is disabled.
// If it is disabled, we do wrap the card in a div, since this card shouldn't link to anything.
// Else, we provide a link to the events.
const CardWrapper: React.FC<ICardWrapper> = (props) => {
  const { actions } = useContext(StoreContext);
  if (props.disabled) {
      return (
    <div {...props}>
        {props.children}
      </div>
    );
  }

  return (
    <Link onClick={() => actions.setPrevious(false)} {...props}>
      {props.children}
    </Link>
  );
};

const Card: React.FC<IProps> = (props) => {
  const { state } = useContext(StoreContext);
  const { title, subtitle, image, topImage, small, link, disabled } = props;
  const backgroundImage = `url(${process.env.REACT_APP_DATA_URL}${image})`;
  const [activeClass, setActiveClass] = useState('');
  // When the component first mounts, we add the class 'show'.
  // This will trigger the animation.
  useEffect(() => {
    setActiveClass('show');
  }, []);
  // When the component updates (in this case the prop locale updates),
  // We remove the active class to animate out the component and after
  // a delay we reset the active class to 'show' to trigger the animation again.
  useEffect(() => {
    const handleClassUpdate = () => {
      setActiveClass('');
      setTimeout(() => {
        setActiveClass('show');
      }, 250);
    };

    handleClassUpdate();
  }, [state.locale]);

  return (
    <CardWrapper disabled={disabled} className={`card ${small ? 'card__small' : ''} ${disabled ? 'card__disabled' : ''} ${activeClass}`} style={{ backgroundImage }} to={link}>
      <div className="card--title-wrapper">
        { topImage && (
          <img className="card--top-image" src={topImage} alt={title}/>
        ) }
        <h1 className="card--title">{title}</h1>
        { subtitle && (
          <h2 className="card--subtitle">{subtitle}</h2>
        ) }
      </div>
    </CardWrapper>
  );
};

export default Card;
