import { GET_REGIONS, SET_ACTIVE_POI, SET_ACTIVE_ROUTE, SET_LOCALE, SET_PREVIOUS } from './types';

const initialState: any = {
  locale: 'dutch',
  localeAbbr: 'nl',
  regions: localStorage.getItem('regions'),
  activePoi: null,
  activeRoute: null,
  previous: false,
};

// interface IActionType {
//   type: 'SET_LOCALE';
//   payload: string;
// }

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_REGIONS:
      return {
        ...state,
        regions: action.payload,
      };
    case SET_LOCALE:
      return {
        ...state,
        locale: action.payload.locale,
        localeAbbr: action.payload.abbr,
      };
    case SET_ACTIVE_POI:
      return {
        ...state,
        activePoi: action.payload,
        activeRoute: null,
      };
    case SET_ACTIVE_ROUTE:
      return {
        ...state,
        activeRoute: action.payload,
        activePoi: null,
      };
    case SET_PREVIOUS:
      return {
        ...state,
        previous: action.payload,
      };
    default:
      return state;
  }
};
export { initialState, reducer };
