import React, { useContext, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import Slider from "react-slick";
import Event from '../../components/Event/Event';
import Footer from '../../components/Footer/Footer';
import Loader from '../../components/Loader/Loader';
import SwipeIcon from '../../components/svg/SwipeIcon';
import { UIT_API_KEY } from '../../config';
import { StoreContext } from '../../context/StoreContext';
import { translate } from '../../translate';
import './Events.scss';
import './Slick.css';
import './SlickTheme.css';

const LABEL = 'toerisme';

interface ILocalities {
  [key: string]: string[];
}

interface ILocation {
  postalCode: string;
  streetAddress: string;
  addressLocality: string;
  name: string;
}

interface IEventData {
  title: string;
  image: string;
  startDate: string;
  endDate: string;
  dates: any[];
  description: string;
  location: ILocation;
  price: string;
  ageRange: any;
  url: string;
  website: string|boolean;
  openingHours: []|boolean;
}

// Get the postalcodes or regions based on the current page.
const getLocalitiesByRegion = (regionId:string) => {
  const localities: ILocalities = {
    '1' : ['gem-tervuren'],
    '3': ['reg-groene-gordel', 'reg-leuven'],
  };

  return localities[regionId] || false;
};

// Get the media from brussels of type "photo".
const getImageFromBrusselsApi = (media:any) => {
  if (!media || Array.isArray(media) && media.length <= 0) {
    return false;
  }

  if (!Array.isArray(media)) {
    return media.link;
  }

  const images = media.filter((file:any) => file.type === 'photo');

  if (images.length <= 0) {
    return false;
  }

  images.sort((a, b) => {
    return a.width - b.width;
  });

  return images[0].link;
};

// We only need the dates of the event that haven't passed yet.
const getFutureDates = (dates:any) => {
  if (Array.isArray(dates) && dates.length > 0) {
    // Get future dates, limited by 10.
    return dates.filter((date) => new Date(date.day) >= new Date()).slice(0, 5);
  }

  return dates;
};

const formatBrusselsDates = (event:any, dates:any) => {
  let startDate;
  let endDate;
  if (dates.length > 0) {

    startDate = (Array.isArray(dates))
      ? `${dates[0].day} ${dates[0].start}`
      : `${dates.day} ${dates.start}`;

    endDate = (Array.isArray(dates))
      ? `${dates[dates.length - 1].day} ${dates[dates.length - 1].end || 'none'}`
      : `${dates.day} ${dates.end || 'none'}`;

  } else {
    startDate = `${event.date_start} ${event.opening ? event.opening.start : 'none'}`;
    endDate = `${event.date_end} ${event.opening ? event.opening.end : 'none'}`;
  }

  return { startDate, endDate };
};

// Only get the first part of a url.
const formatWebsite = (website:any) => {
  if (!website) {
    return false;
  }

  if (Array.isArray(website) && website.length <= 0) {
    return false;
  }

  website = (Array.isArray(website)) ? website[0] : website;
  // Get the first path of the url until the first '/' after http(s).
  const splittedWebsite = website.split('/');
  return splittedWebsite[0] + '//' + splittedWebsite[2];
};

// Handle fetch request.
const fetchFromUit = async (url:string) => {
  try {
    url = encodeURIComponent(url);
    const data = await fetch(process.env.REACT_APP_DATA_URL + '/api/uitinvlaanderen?url=' + url);
    const json = await data.json();
    return json;
  } catch (err) {
    console.error(err);
  }
};

// Get data from Uit in Vlaanderen.
const getEventsFromUit = async (postalCode:string[], regionId: string) => {
  let events;
  let regionEvents;
  let url = `${process.env.REACT_APP_UIT_URL}/offers/?apiKey=${UIT_API_KEY}&embed=true&sort[availableTo]=asc&limit=1000`;

  // Async map function returns promises. To resolve these we call Promise.all.
  if (regionId.toString() === '3') {
    regionEvents = postalCode.map(async (region:any) => {
      url += `&regions=${region}&labels[]=${LABEL}`;
      const data = await fetchFromUit(url);
      return data;
    });
  } else {
    url += '&labels[]=visittervuren';
    regionEvents = await fetchFromUit(url);
  }

  events = await Promise.all(regionEvents);
  events = events.flat();

  // Sort events by startdate of event.
  events = events.sort((a:any, b:any) => {
    const startDateA = new Date(a.startDate);
    const startDateB = new Date(b.startDate);
    const now = new Date();

    return (startDateA < now || startDateB < now) ? 0 : startDateA.getTime() - startDateB.getTime();
  });

  return events;
};

// Fetch data from Brussels API. We get 25 results back for each page. So we merge the results based on the page count.
const getEventsFromBrussels = async () => {
  try {
    const response = await fetch(`${process.env.REACT_APP_DATA_URL}/api/brussels`);
    const data = await response.json();
    // Return events that aren't finished yet.
    const events = data.filter((event:any) => {
      const eventDate = new Date(event.date_next).setHours(0, 0, 0, 0);
      const now = new Date().setHours(0, 0, 0, 0);

      return eventDate >= now;
    });

    return events.slice(0, 30);

  } catch (err) {
    console.error(err);
    return [];
  }
};

// Format the data returned from the Brussels API to the data the Event component expects.
const formatDataFromBrussels = (event:any, locale:string) => {
  const dates = getFutureDates(event.dates);
  const { startDate, endDate } = formatBrusselsDates(event, dates);
  const locationData = event.place.translations[locale];
  const price = (event.prices) ? (Array.isArray(event.prices) && event.prices.length > 0) ? event.prices[0].value : event.prices.value : 0;
  const image = getImageFromBrusselsApi(event.media);

  return {
    title: event.translations[locale].name || '',
    image,
    startDate,
    endDate,
    dates,
    description: event.translations[locale].longdescr || '',
    location: {
      name: locationData.name,
      streetAddress: locationData.addess_line1,
      postalCode: locationData.address_zip,
      addressLocality: locationData.address_city,
    },
    price,
    ageRange: false,
    url: event.translations[locale].agenda_url,
    website: formatWebsite(event.translations[locale].website),
    openingHours: false,
  };
};

// Get dates from not finished events.
const getDatesFromUit = (event:any) => {
  // Filter out dates that are finished.
  const futureDates = event.subEvent.filter((dates:any) => (new Date(dates.startDate) >= new Date()));
  // Return formatted dates.
  return futureDates.map(( eventDates:any ) => ({ startDate: eventDates.startDate, endDate: eventDates.endDate })).slice(0, 5);
};

// Format data from Uit in Vlaanderen API to the data the Event component expects.
const formatDataFromUitInVlaanderen = (event:any, locale:string) => {
  let location:any = false;
  const locationName = event.location ? event.location.name.nl || event.location.name : '';
  const { streetAddress, postalCode, addressLocality } =
    event.location
      ? event.location.address.nl || event.location.address
      : event.address ? event.address.nl : [];
  location = { name: locationName, streetAddress, postalCode, addressLocality };
  const dates = event.calendarType === 'multiple'
    ? getDatesFromUit(event)
    : [];

  return {
    title: event.name[locale] || '',
    image: event.image,
    startDate: event.startDate,
    endDate: event.endDate,
    dates,
    description: event.description ? event.description[locale] : '',
    location,
    price: event.priceInfo ? event.priceInfo[0].price : undefined,
    ageRange: event.typicalAgeRange || false,
    url: event.sameAs ? event.sameAs[0] : '',
    website: formatWebsite(event.seeAlso),
    openingHours: event.openingHours && event.openingHours.length > 0 ? event.openingHours : false,
  };
};

const Events: React.FC<RouteComponentProps> = ({ history, ...props }: any) => {
  const [events, setEvents] = useState<any>({});
  const [swiper, setSwiper] = useState(false);
  const { state } = useContext(StoreContext);
  const { localeAbbr } = state;
  const { region: regionId } = props.match.params;

  useEffect(() => {
    const fetchData = async () => {
      const region = getLocalitiesByRegion(regionId);
      let data = [];
      if (region && regionId !== '2') {
        data = await getEventsFromUit(region, regionId);
      } else {
        data = await getEventsFromBrussels();
      }

      setEvents(data);

      setTimeout(() => {
        if (events.length > 4) {
          setSwiper(true);
        }
      }, 1000);
    };

    fetchData();
  }, [props.match.params.region]);

  let countSlides = 0;

  if (events) {
    countSlides = (events.length >= 4) ? 4 : events.length;
  }

  const slickSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: countSlides,
    touchThreshold: 20,
    swipeToSlide: true,
    onSwipe: () => swiper && setSwiper(false),
    centerMode: true,
    centerPadding: '60px',
    initialSlide: 2,
    arrows: false,
  };

  return (
    <div className="page events page--wrapper">
      { events && events.length > 0 ? (
        <>
          <Slider {...slickSettings}>
            { events.map((event:any, i:number) => {
              let eventData;
              // Format the data of the API's to the same object.
              if (regionId === '2') {
                eventData = formatDataFromBrussels(event, localeAbbr);
              } else {
                eventData = formatDataFromUitInVlaanderen(event, localeAbbr);
              }

              const { title, image, startDate, endDate, dates, description, location, price, ageRange, url, website, openingHours } = eventData;

              return (
                <Event
                  key={i}
                  title={title}
                  image={image}
                  startDate={startDate}
                  endDate={endDate}
                  dates={dates}
                  description={description}
                  location={location}
                  price={price}
                  ageRange={ageRange}
                  locale={localeAbbr}
                  url={url}
                  website={website}
                  openingHours={openingHours}
                />
              );
            })}
          </Slider>
          <div className={`swipe-icon ${swiper ? 'show' : ''}`}>
            <SwipeIcon fill="#ffffff" />
          </div>
        </>
      ) : (
        <Loader message={translate("No events were found", localeAbbr)} />
      )}
      <Footer />
    </div>
  );
};

export default Events;