import { useContext, useEffect, useState } from 'react';
import { StoreContext } from '../context/StoreContext';

interface IFilter {
  key: string;
  value: string;
}

const useFetch = (endpoint:string, withLocale:boolean, filter?:IFilter) => {
  const [data, setData] = useState([]);
  const { state } = useContext(StoreContext);
  const url = (withLocale)
    ? `${process.env.REACT_APP_DATA_URL}/${state.locale}/${endpoint}`
    : `${process.env.REACT_APP_DATA_URL}/${endpoint}`;

  useEffect(() => {
    (async () => {
      const currentDay = new Date().getDay();

      if (endpoint.includes('regio/coords')) {
        const coords = localStorage.getItem(endpoint);

        if (coords) {
          setData(JSON.parse(coords));
          return;
        }
      }

      if (endpoint === 'front-images') {
        // Check if images are in local storage. If so, return them.
        const images = localStorage.getItem('front-images');
        const dayImagesSet = localStorage.getItem('imagesSetOn');
        if (images && dayImagesSet && currentDay - parseInt(dayImagesSet, 10) === 0) {
          setData(JSON.parse(images));
          return;
        }
      }

      const res = await fetch(url);
      const json = await res.json();
      // Cache front images.
      if (endpoint === 'front-images') {
        localStorage.setItem('front-images', JSON.stringify(json));
        localStorage.setItem('imagesSetOn', new Date().getDay().toString());
      }

      if (endpoint.includes('regio/coords')) {
        localStorage.setItem(endpoint, JSON.stringify(json));
      }

      setData(json);
    })();
  }, [url]);

  data.sort((a:any, b:any) => {
    return a.field_gewicht ? (parseInt(a.field_gewicht, 10) - parseInt(b.field_gewicht, 10)) : 0;
  });

  if (!filter) {
    return data;
  }

  // Filter the data based on filter parameter.
  const filteredData = data.filter((item) => item[filter.key] === filter.value);

  return filteredData;
};

export default useFetch;