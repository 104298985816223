import React from 'react';

interface IProps {
  fill: string;
}

const DrawnArrowIcon: React.FC<IProps> = (props) => (
  <svg id="Objects" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 55.62 24.97">
    <path fill={props.fill} d="M55.33,12.54c-.36.4-.71.81-1,1.22A42.47,42.47,0,0,0,48.73,24.3c-.45,1.26-2.63.59-2.25-.71,0-.1.07-.19.1-.29h0v0A32.26,32.26,0,0,1,50,15.63C32.4,24.12,12.29,15.66.13.89-.35.3.6-.34,1.09.21c12.5,14,33,22,50.11,12.11L39.08,9.76a1.2,1.2,0,0,1,.43-2.35l14.23,2.2a1.05,1.05,0,0,1,.94,1A1.2,1.2,0,0,1,55.33,12.54Z"/>
  </svg>
);

export default DrawnArrowIcon;