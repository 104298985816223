import translations from './translations';

interface ILabel {
  [key: string]: string;
}

// Translate a string to the given language.
export const translate = (label:string|boolean, language:string, sourceLanguage: string = 'en'): string|boolean => {
  if (!label || language === sourceLanguage) {
    return label;
  }
  // Find the translation corresponding to the given source.
  const labels = translations.filter((translation:any) => translation[sourceLanguage] === label);
  // Translation not found.
  if (labels.length <= 0) {
    return label;
  }
  // Return the transation of the label.
  const translatedLabel: ILabel = labels[0];
  return translatedLabel[language];
};