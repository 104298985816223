import { useContext } from "react";
import { StoreContext } from "../context/StoreContext";
import useTranslatedRegions from "./useTranslatedRegions";

// Get current region.
const useCurrentRegion = (regionId:string|number) => {
  const { state } = useContext(StoreContext);
  const regions = useTranslatedRegions(state.locale);
  const currentRegion = regions.length > 0 ? regions.filter((region:any) => region.tid === regionId) : [{ field_coordinates: '' }];

  return currentRegion[0];
};

export default useCurrentRegion;