import * as Sentry from '@sentry/browser';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { StoreProvider } from './context/StoreContext';
import * as serviceWorker from './serviceWorker';

Sentry.init({ dsn: "https://8f47e1afc32c45f992c5fa1e9c3222d5@sentry.io/2238172" });

const rootElement = document.getElementById('root');
ReactDOM.render(
  <StoreProvider>
    <App />
  </StoreProvider>,
  rootElement
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
