import { useContext } from "react";
import { StoreContext } from "../context/StoreContext";

const useTranslatedRegions = (locale:string) => {
  const { state, actions } = useContext(StoreContext);
  let regions;

  // If regions come from local storage, we need to parse them and get the translated regions.
  if (state.regions) {
    regions = JSON.parse(state.regions);
  } else {
    // Set regions in local storage.
    actions.getRegions(locale);
    const localRegions = localStorage.getItem('regions');
    regions = localRegions ? JSON.parse(localRegions) : [];
  }

  return regions.filter((region:any) => region.langcode.toLowerCase() === locale);
};

export default useTranslatedRegions;