import React from 'react';

interface IProps {
  fill?: string;
}

const LocationIcon: React.FC<IProps> = (props) => (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="370.9px" height="512px" viewBox="0 0 370.9 512" enableBackground="new 0 0 370.9 512;">
    <path fill={props.fill} d="M185.4,0C83.2,0,0,83.2,0,185.4c0,126.9,165.9,313.2,173,321c6.6,7.4,18.2,7.4,24.8,0c7.1-7.9,173-194.1,173-321
      C370.9,83.2,287.7,0,185.4,0z M185.4,278.7c-51.4,0-93.3-41.9-93.3-93.3s41.9-93.3,93.3-93.3s93.3,41.9,93.3,93.3
      S236.9,278.7,185.4,278.7z"/>
  </svg>
);

export default LocationIcon;